import { Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { createCategories, createCouponCode, getCategories, getCouponCodeById } from "app/api/printNode";
import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CategoriesForm from "./CouponCodesForm";
import CouponCodesForm from "./CouponCodesForm";
import DenseTable from "app/components/DenseTable";

const CouponCodesView = () => {
    const title = 'coupon code';


    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const navigate = useNavigate();
    const { couponCodeId } = useParams();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getCouponCodeById(couponCodeId).then((res) => {
            setData(res.data.result.user_prints)
            console.log(res.data.result.user_prints)
        })
        setLoading(false)
    }, [])

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Coupon Code Create" }]} />
            </Box>
            <SimpleCard title="Coupon Code history">
                {loading ? <MatxLoading/> : <DenseTable rows={data} />}
            </SimpleCard>
        </Container>
    );
};

export default CouponCodesView;
