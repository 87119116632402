import { io } from 'socket.io-client';

const PRINT_SERVER_URL = 'https://costa-coffee-socket.tech-and-beyond.org'; // Replace with your print server URL

class PrintService {
  constructor() {
    this.socket = io(PRINT_SERVER_URL);
    this.socket.on('connect', () => {
      console.log('Connected to print server');
    });
  }

  printFile(data) {
    this.socket.emit('printPDF', data);
  }

  onPrintStatus(callback) {
    this.socket.on('printStatus', callback);
  }

  disconnect() {
    this.socket.disconnect();
  }
}

const printService = new PrintService();
export default printService;
