import { Avatar, Box, Card, Grid, Paper, Typography, styled } from '@mui/material'
import { getActiveCategories } from 'app/api/printNode';
import { MatxLoading } from 'app/components';
import translations from 'app/utils/ar';
import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import enTranslations from '../../utils/en';
import arTranslations from '../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';

function CategoriesGrid(props) {

    const { type } = props
    const { printerId } = useParams();
    const [loading, setLoading] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations;


    useState(() => {
        setLoading(true);
        getActiveCategories(type).then((res) => {
            setCategoriesOptions(res.data.result.data);
            setLoading(false);
        });
        return () => {
            setCategoriesOptions({});
            setLoading(false);

        }
    }, []);

    const handleClick = (id) => {
        console.log(id);
        const url = `/print-manual/${printerId}/${id}/${type}`;
        navigate(url);
    }


    return (
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
          
            <Box key={0}>
                <center>
                    <Typography
                        variant="h3"
                        sx={{
                            paddingBottom: '20px',
                            color: '#0AE3FF'
                        }}
                    >
                        {translations.category_title}
                    </Typography>
                </center>
            </Box>
            <Grid
                container
                item
                gap={'20px'}
                spacing={5}
                justifyContent="center"
                alignItems="stretch"
                sx={{
                   
                    margin: '0',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                {(loading) ? <MatxLoading /> :
                    categoriesOptions.map((item) => {
                        // console.log(item)
                        return <Grid
                            item
                            sx={{
                                backgroundColor: '#fff',
                                border: '4px solid #0AE3FF',
                                padding: '0 !important',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                                height: 500,
                                width: 500,
                                borderRadius: 5,
                                overflow: 'hidden',
                                "&:hover":{
                                    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
                                    cursor: 'pointer',
                                }
                            }} key={item.id} onClick={() => handleClick(item.id)}>
                            <Avatar
                                variant="square"
                                alt={item.name}
                                src={item.image}
                                sx={{
                                    height: '80%',
                                    width: 'auto',
                                    maxWidth: '95%',
                                    borderRadius: 5,
                                    marginTop: 1,
                                    alignSelf: 'center',
                                }}
                            />
                            <Typography
                                style={{ color: 'black', fontWeight: 600, fontSize: 30, margin: 'auto', textAlign: 'center'}}
                                variant="p"
                            >
                                {item.name}
                            </Typography>
                        </Grid>
                    })
                }
            </Grid>
        </Grid>

        // 


    )
}

export default CategoriesGrid
