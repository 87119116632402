export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const projectTitle = 'Print & Go'
// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// api key for print node
// 8-h2MMSl4NYq8AnG4r5ZabRMFVM1ShISZm60g0JjuVY
export const printNodeAPIKey = "S8dVA5X8N4b-I_ASc99bu3cbdIMIqN6JMQJe3UR43UM" // for dhaval.sn007@gmail.com
// export const printNodeAPIKey = "lel5VzKOQEJUAlXsXPFlcgFlrWziakf4PtGd0ofFkHM" // for info@codeflixwe
export const printNodeAPIUrl = "https://api.printnode.com"
export const baseUrl = "https://api-printngo.tech-and-beyond.com/api" // live server api url
// export const baseUrl = "https://api.print-less.com/" //local setup api url
