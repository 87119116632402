import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function DenseTable({rows}) {
console.log(rows);
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">IP Address</TableCell>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="center">Print Form</TableCell>
                        <TableCell align="center">Amount</TableCell>
                        <TableCell align="center">Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {row.user_ip}
                            </TableCell>
                            <TableCell align="center">
                                {(row.form) ? <span>Form Print</span> : <span>Manual</span>}
                                </TableCell>
                            <TableCell align="center">{(row.form) ? row.form.name : 'Manual'}</TableCell>
                            <TableCell align="center">{row.print_amount}</TableCell>
                            <TableCell align="center">{row.created_at}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}