import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import { addUserValue, createUserForm, getFormById, printFile } from 'app/api/printNode';
import { ErrorMessage, Formik } from 'formik';
import { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MaxWidthDialog from '../material-kit/dialog/OptimalSizeDialog';
import enTranslations from '../../utils/en';
import arTranslations from '../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';
import { trim } from 'lodash';
import { H1, H2 } from 'app/components/Typography';
import FormCanvas from 'app/components/FormCanvas';



const FormField = () => {
    const { formId, printerId } = useParams();
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations;

    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState({});
    const [form, setForm] = useState({});
    const [formField, setFormField] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        printerId: printerId,
        count: 1,
        color: 'gray-scale',
    });

    const theme = createTheme({
        typography: {
            body1: {
                fontSize: 30
            }, // Adjust the base font size here
            // You can also set other typography options like fontWeight, lineHeight, etc.
        },
    });

    // inital login credentials
    const initialValues = {
        printerId: printerId,
        count: 1,
        color: 'gray-scale',
    };

    useState(() => {
        getFormById(formId).then((res) => {
            setForm(res.data.result.data);
            setFormField(res.data.result.data.form_field);
            setPrice({ price_black_and_white: res.data.result.data.price_black_and_white, price_color: res.data.result.data.price_color });

        })
    }, []);


    const handleFormSubmit = (values, { setFieldError }) => {
        setLoading(true);

        try {

            createUserForm({ form_id: form.id }).then((res) => {
                const user_form_id = res.data.result.id;
                const entries = Object.entries(formField);
                const numEntries = entries.length;
                if (entries.length > 0) {
                    entries.forEach((item, index) => {
                        if (item[1].value != "" && item[1].value != undefined) {

                            const formData = {
                                user_form_details_id: res.data.result.id,
                                form_field_id: item[1].id,
                                value: item[1].value
                            };

                            addUserValue(formData)
                                .then((res) => {
                                    if (index === numEntries - 1) {
                                        setTimeout(() => {
                                            const url = `/generate-image/${user_form_id}/${printerId}`;
                                            navigate(url);
                                        }, 1000)
                                    }
                                });
                        } else {
                            if (index === numEntries - 1) {
                                setTimeout(() => {
                                    const url = `/generate-image/${user_form_id}/${printerId}`;
                                    navigate(url);
                                }, 1000)
                            }
                        }

                    });
                } else {
                    const printData = {
                        file_url: res.data.result.form.image,
                        is_color: state.color != 'gray-scale',
                        count: state.count,
                        printer_id: printerId,
                    };

                    printFile(printData).then((response) => {
                        console.log(response.data);
                        // setIsOpen(true);
                        // setLoading(false);
                        const url = `/payment/${response.data.result.id}/${formId}/${printerId}`;
                        navigate(url);

                    })
                }


            })
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const validateForm = (values) => {


        var errors = {};

        const entries = Object.entries(formField);
        // const numEntries = entries.length;
        if (entries.length > 0) {
            entries.forEach((item, index) => {
                if (item[1].is_required == '1') {
                    if (typeof item[1].value == 'undefined' || !item[1].hasOwnProperty('value')) {
                        let label = item[1].label;
                        errors = {
                            ...errors,
                            [item[1].label]: `${label} is required`
                        }

                    } else {
                        if (trim(item[1].value) == '') {
                            let label = item[1].label;
                            errors = {
                                ...errors,
                                [item[1].label]: `${label} is required`
                            }
                            // errors.{item[1].label} = `${label} is required`;

                        }
                    }
                };
            })
        }
        return errors;
    };

    return (
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
    >

        <Box key={0}>

            <center>
                <Typography
                    variant="h4"
                    sx={{
                        paddingBottom: '60px',
                        color: 'black'
                    }}
                >
                    {'Fill the necessary details to proceed with the printing request'}
                </Typography>
            </center>
        </Box>
        <Grid
            container
            item
            sx={{
                width: '100%',
                padding: '0 15px',
                boxSizing: 'border-box',
                margin: '0'
            }}
            gap={'20px'}
            spacing={5}
            justifyContent="center"
            alignItems="center"

        >
            <Formik
                validate={validateForm}
                onSubmit={handleFormSubmit}
                initialValues={state}
            // validationSchema={validationSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <ThemeProvider theme={theme}>
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    alignItems: "center",

                                }}
                            >
                                {!(form.form_field && form.form_field.length > 0) ? <></> : <FormCanvas forms={form} setPosition={() => { }} printerId={printerId} />}


                                {
                                    (form.form_field && form.form_field.length == 0) &&
                                    <>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="gray-scale"
                                            name="color"
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                setState({ ...state, color: newValue });
                                            }}
                                            helperText={touched.color && errors.color}
                                            error={Boolean(touched.color && errors.color)}
                                            sx={{ mb: 1.5 }}
                                        >
                                            <FormControlLabel value="color" control={<Radio />} label={translations.color} />
                                            <FormControlLabel value="gray-scale" control={<Radio />} label={translations.gray_scale} />
                                        </RadioGroup>

                                        <H2 sx={{ marginBottom: 2 }} >{translations.copy_label}</H2>
                                        <TextField
                                            fullWidth
                                            size="large"
                                            type="number"
                                            name="count"
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            label={translations.copy}
                                            variant="outlined"
                                            value={state.count}
                                            color="secondary"
                                            sx={{
                                                marginBottom: '10px',
                                            }}
                                            onChange={(event, newValue) => {
                                                setFieldValue('count', event.target.value);
                                                setState({ ...state, count: event.target.value });
                                            }}
                                            helperText={touched.count && errors.count}
                                            error={Boolean(errors.count && touched.count)}
                                        />

                                        <LoadingButton
                                            type="submit"
                                            color="primary"
                                            sx={{
                                                width: '40%',
                                                my: 2,
                                                // marginLeft: "25%",
                                                backgroundColor: "yellow",
                                                color: "black",
                                                "&:hover": {
                                                    backgroundColor: "white", // Change background color on hover
                                                    color: "black", // Change text color on hover
                                                },
                                            }}
                                            loading={loading}
                                            variant="contained"
                                        >
                                            {translations.print}
                                        </LoadingButton>
                                    </>
                                }
                            </div>
                        </form>
                    </ThemeProvider>
                )}
            </Formik>
        </Grid>
        {isOpen && (
            <MaxWidthDialog
                open={isOpen}
                setIsOpen={setIsOpen}
                state={state}
                setting={price}
                files={{}}
                printerId={printerId}
                isForm={true}
            />
        )}
    </Grid >

    );
};

export default FormField;
