import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import categoryRoutes from './views/categories/CategoryRoutes';
import computerRoutes from './views/computers/ComputerRoutes';
import formsRoutes from './views/forms/FormsRoutes';
import printRoutes from './views/print/printRoutes';
import printerRoutes from './views/printers/PrinterRoutes';
import printJobsRoutes from './views/printJobs/PrintJobsRoutes';
import settingRoutes from './views/settings/settingRoutes';
import subCategoryRoutes from './views/sub-categories/SubCategoryRoutes';
import PrintFormRoutes from './views/print-form/PrintFormRoutes';
import QrCodeRoutes from './views/qr-code/QrCodeRoutes';
import FrontLayout from './components/MatxLayout/FrontLayout';
import linksRoutes from './views/links/LinksRoutes';
import MoiRoutes from './views/moi/MoiRoutes';
import couponCodeRoutes from './views/coupon-code/CouponCodeRoutes';
import paymentRoutes from './views/payment/paymentRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...printerRoutes, ...computerRoutes, ...printJobsRoutes, ...printRoutes, ...settingRoutes, ...categoryRoutes, ...subCategoryRoutes, ...formsRoutes, ...linksRoutes, ...couponCodeRoutes],
  },
  {
    element: (
        <FrontLayout/>
    ),
    children: [...PrintFormRoutes, ...QrCodeRoutes, ...paymentRoutes],
    // children: [],
  },
  ...sessionRoutes,
  ...MoiRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
