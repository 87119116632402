import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createCategories, createCouponCode, getCategories } from "app/api/printNode";
import { Breadcrumb, SimpleCard } from "app/components";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CategoriesForm from "./CouponCodesForm";
import CouponCodesForm from "./CouponCodesForm";

const CouponCodesCreate = () => {
    const title = 'coupon code';
    
    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [admin, setAdmin] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const currentCouponCode = { amount: "", description: "", expiry_date: "",code:"" }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("amount", state.amount)
        formData.append("code", state.code)
        formData.append("description", state.description)
        formData.append("expiry_date", state.expiry_date)
        
        createCouponCode(formData).then((res) => {
        navigate("/coupon-code");
            
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Coupon Code Create" }]} />
            </Box>
            <SimpleCard title="Coupon Code Form">
                <CouponCodesForm handleSubmit={handleSubmit} tableData={currentCouponCode} />
            </SimpleCard>
        </Container>
    );
};

export default CouponCodesCreate;
