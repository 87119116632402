import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Payment from './Payment';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailed from './PaymentFailed';

const paymentRoutes = [
  { path: '/payment/:printFileId/:printerId', element: <Payment />, auth: authRoles.admin },
  { path: '/payment/:printFileId/:formId/:printerId', element: <Payment />, auth: authRoles.admin },
  { path: '/succes-payment/:printerId', element: <PaymentSuccess />, auth: authRoles.admin },
  { path: '/failed-payment/:printerId', element: <PaymentFailed />, auth: authRoles.admin },
];

export default paymentRoutes;
