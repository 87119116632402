import {
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const CouponCodesForm = ({ handleSubmit, tableData }) => {

    const [state, setState] = useState({ amount: tableData.amount, description: tableData.description, code:tableData.code, expiry_date: tableData.expiry_date });
    const handleChange = (event) => {
        
        setState({ ...state, [event.target.name]: event.target.value });
        
    };


    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="amount"
                            id="amount"
                            value={state.amount || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Amount"
                            validators={["required"]}
                        />
                    </Grid>
                    {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="expiry_date"
                            id="expiry_date"
                            value={state.expiry_date || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Expiry Date ( YYYY-MM-DD )"
                            // validators={["required"]}
                        />
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="code"
                            id="code"
                            value={state.code || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Coupon Code"
                            // validators={["required"]}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="description"
                            id="description"
                            value={state.description || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Description"
                            // validators={["required"]}
                        />
                    </Grid>
                  
                    


                </Grid>

                <Button color="primary" variant="contained" type="submit" sx={{mt:2}}>
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default CouponCodesForm;
