import { Box, styled } from "@mui/material";


import { H1 } from "app/components/Typography";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
const FlexBox = styled(Box)(() => ({
  display: "flex",
}));
const ForgotPasswordRoot = styled(FlexBox)(({ theme }) => ({
    // flexDirection: "column", // Default flex-direction
  
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", // Change flex-direction for medium screens and larger
    },
    background: "#FFFFFF",
    minHeight: "100vh !important",
    padding: 30,
  }));
  const TopSection = styled(Box)(() => ({
    // background: '#ff4444',
    flex: 1,
    maxWidth:"100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }));
  const LogoContainer = styled(Box)(({ theme }) => ({
    height: 500,
    width: 500,
    display:"flex",
    flexDirection: "column",
    alignItems:"center",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      height: 700,
      width: 700,
    },
    // animation: 'rotateAnimation 2s infinite',
    '@keyframes rotateAnimation': {
      'from': {
        transform: 'rotateY(45deg)',
      },
      'to': {
        transform: 'rotateY(225deg)',
      }
    }
  }));
    
  const Logo = styled("img")(() => ({
    maxWidth: "100%",
    maxHeight: "100%",
    width: "80%",
    height: "auto",
    display: "block" /* Remove extra space beneath the image */,
    margin: "0 auto" /* Center the image horizontally (optional) */,
  }));
  const TitleAr = styled(H1)(({ theme }) => ({
    fontFamily: "Inter",
    color: "#696969",
    marginTop: 10,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "28.73px",
    textAlign: "center",
  
    [theme.breakpoints.up("md")]: {
      fontWeight: 800,
      fontSize: 32,
      lineHeight: "38.73px",
    },
  }));
  const TitleEn = styled(H1)(({ theme }) => ({
    // marginTop: 15,
    fontFamily: "Inter",
    color: "#696969",
    fontWeight: 600,
    fontSize: 32,
    lineHeight: "28.73px",
    textAlign: "center",
    paddingTop: "35px",
  
    [theme.breakpoints.up("md")]: {
      fontWeight: 800,
      fontSize: 32,
      lineHeight: "38.73px",
    },
  }));
const PaymentFailed =()=>{

  const navigate = useNavigate();
  const { printerId } = useParams();


    setTimeout(
      navigate(`/moi/${printerId}`)
      , 10000);
  
    return (
        <ForgotPasswordRoot> 
          <TopSection>
              <LogoContainer>
                <Logo src="/assets/images/print-and-go/payment_failed.png" />
              <TitleEn>Failed</TitleEn>
              </LogoContainer>
              <TitleAr style={{ fontSize: "38px" }}>
                {/* خدمة طباعة و fدفع الكتروني ذاتية */}
              </TitleAr>
            </TopSection> 
            </ForgotPasswordRoot>
    );
}


export default PaymentFailed;