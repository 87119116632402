import { LoadingButton } from '@mui/lab';
import {
  Box, Grid, TextField, Typography, styled,
  Avatar,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { checkout, checkoutEmpty, getFormById, getPrintFile, getSettings, getSinleCoupon, storePrint } from 'app/api/printNode';
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import enTranslations from "../../utils/en";
import arTranslations from "../../utils/ar";
import TextBetweenLines from 'app/components/TextBetweenLines';

const FlexBox = styled(Box)(() => ({
  display: "flex",
}));

const RootContainer = styled(FlexBox)(({ theme }) => ({
  flexDirection: "column", // Default flex-direction

  [theme.breakpoints.up("md")]: {
    // flexDirection: "row", // Change flex-direction for medium screens and larger
  },
  background: "#FFFFFF",
  // minHeight: "100vh !important",
  // padding:'0 30 30 30',
  paddingBlock: '10px',
  alignItems: "center",
}));

const Left = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "start",
  alignSelf: "center",
  justifyContent: "space-between",
  height: "100%"
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
  },
  marginTop: '15%'
}));

const Logo = styled("img")(() => ({
  maxWidth: "100%",
  maxHeight: "100%",
  width: "80%",
  height: "auto",
  display: "block" /* Remove extra space beneath the image */,
  margin: "0 auto" /* Center the image horizontally (optional) */,
}));

const Right = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  // marginInline: '10%'
}));

const FieldSet = styled('fieldset')(({ theme }) => ({
  background: 'linear-gradient(white, white) padding-box, linear-gradient(270deg, #0076E2, #0AE3FF) border-box',
  borderRadius: '8px',
  border: '3px solid transparent',
  width: '-webkit-fill-available',
  [theme.breakpoints.up("md")]: {
    width: '45%'
  },
  paddingBottom: '10px'
}));

const Legend = styled('legend')(() => ({
  fontWeight: '600',
  fontSize: '32px',
  color: '#000000',
  width: 'fit-content',
  backgroundColor: 'white',
  marginLeft: '15px',
  paddingInline: '5px',
  textAlign: "center",
  height: "100%",
  justifyContent: "space-between"
}))

const TextContainer = styled(FlexBox)(() => ({
  justifyContent: 'space-between',
  marginInline: '20px',
  marginBlock: '10px'
}))

const Item = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  // height: 200,
  color: 'black',
  width: '100%',
  overflow: "hidden",
  // padding: "15px",
  gap: '56px',
  alignItems: 'center',
  // [theme.breakpoints.up("md")]: {
  //   height: 220,
  //   width: '100%',
  // },
}));

const CardContainer = styled('div')(({ theme }) => ({
  display: "flex",
  gap: '48px'
}));

const CouponContainer = styled('div')(({ theme }) => ({
  display: "flex",
  // gap: '24px',
  flexDirection: "column",
  width: '100%',
  alignItems: "center",
  justifyContent: 'space-between'
}));

const TotalAmountContainer = styled('div')(({ theme }) => ({
  display: "flex",
  gap: '24px',
  alignItems: 'center',
}));

const SavedItem = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  gap: '0px',
  alignItems: 'start',
  backgroundColor: '#e4f4da',
  color: 'green',
  paddingInline: '10px',
  paddingTop: '5px'
}));
const Test = styled('span')(({ theme }) => ({
  height: '150px',
  width: '50px',
  display: 'none',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  // paddingTop: '10px',
  fontSize: '25px',
  color: '#D0D0D0',
  '&::before': {
    borderLeft: '1px solid #D0D0D0',
    content: '""',
    display: 'block',
    height: '110px',
    left: '26px',
    position: 'relative',
    // top: '30px',
    width: '1px',
    zIndex: 0,
  },
  '&::after': {
    borderLeft: '1px solid #D0D0D0',
    content: '""',
    display: 'block',
    height: '110px',
    left: '26px',
    position: 'relative',
    // top: '140px',
    width: '1px',
    zIndex: 0,
  },
  [theme.breakpoints.up("md")]: {
    display: 'inline-block',
  },
}));

const Payment = () => {

  const [state, setState] = useState({ code: "", hasCode: 1 });
  const [data, setData] = useState({});
  const [printId, setPrintId] = useState(0);
  const [price, setPrice] = useState({});
  const [CouponApplied, setCouponApplied] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { printFileId, formId, printerId } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    getPrintFile(printFileId).then(res => {
      console.log(res.data.result);
      setData(res.data.result);
      if (formId == undefined) {
        getSettings().then((res1) => {
          setPrice(res1.data.result.data[0]);
          let amount = res.data.result.is_color ? res.data.result.count * res1.data.result.data[0].price_color : res.data.result.count * res1.data.result.data[0].price_black_and_white;
          setPayableAmount(amount);
        });

      } else {
        getFormById(formId).then((res1) => {
          setPrice({ price_black_and_white: res1.data.result.data.price_black_and_white, price_color: res1.data.result.data.price_color });
          let amount = res.data.result.is_color ? res.data.result.count * res1.data.result.data.price_color : res.data.result.count * res1.data.result.data.price_black_and_white;
          setPayableAmount(amount);
        })

      }
    })

  }, [])


  const handleCouponApply = (values) => {
    getSinleCoupon(state.code).then(res => {
      if (res.data.result.data.length > 0) {
        let printAmount = payableAmount;
        let printData = {
          print_count: data.count,
          print_type: (data.is_color == 0) ? 2 : data.is_color,
          coupon_code_id: res.data.result.data[0].id
        }

        if (formId != undefined) {
          printData.form_id = formId
        }
        storePrint(printData).then(res => {
          setPrintId(res.data.result.id);
          setCouponApplied(res.data.result.used_coupon_amount)
          setPayableAmount(parseFloat(payableAmount) - parseFloat(res.data.result.used_coupon_amount))
        })
      } else {
        alert('Invalid coupon code')
        return 0;
      }
    })
  }

  const handlePay = () => {

    setLoading(true)
    let printData = {
      print_count: data.count,
      print_type: (data.is_color == 0) ? 2 : data.is_color,
    }

    if (formId != undefined) {
      printData.form_id = formId
    }

    const formData = new FormData();
    formData.append('id', printerId);
    formData.append('total_amount', payableAmount);
    formData.append('lang', 'en');
    formData.append('count', data.count);
    formData.append('color', data.is_color);
    formData.append('file', data.file_url);
    formData.append('src', 'src_kw.knet');


    if (printId === 0) {
      storePrint(printData).then(res => {
        setPrintId(res.data.result.id);
        formData.append('printer_id', parseInt(res.data.result.id));
        checkout(formData).then((res) => {
          console.log(res.data.url);
          window.location.replace(res.data.url);
          setLoading(false)

        })

      })

    } else {
      formData.append('printer_id', parseInt(printId));
      if (payableAmount > 0) {

        checkout(formData).then((res) => {
          window.location.replace(res.data.url);
          setLoading(false)

        })
      } else {
        checkoutEmpty(formData).then((res) => {
          // console.log(res.data.result.data.id);
          const url = `/succes-payment/${printerId}/${res.data.result.data.id}`;
          navigate(url);

        })
      }
    }



  }

  return (
    <RootContainer>

      <Grid
        item
        xs={12}
        md={9}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "48px",
          // borderTop: '2px solid rgb(0 0 0 / 24%)',
          // marginTop: '56px',
          width: '-webkit-fill-available',
        }}
      >
        <FieldSet>
          <Legend>Print Details</Legend>
          <TextContainer>
            <Typography
              fontSize='20px'
              lineHeight='24px'
            >
              Color:
            </Typography>
            <Typography
              fontSize='20px'
              lineHeight='24px'
              fontWeight='500'
              color='black'
            >
              {data.is_color ? 'Color' : 'Black & White'}
            </Typography>
          </TextContainer>
          <TextContainer>
            <Typography
              fontSize='20px'
              lineHeight='24px'
            >
              Total Print:
            </Typography>
            <Typography
              fontSize='20px'
              lineHeight='24px'
              fontWeight='500'
              color='black'
            >
              {data.count}
            </Typography>
          </TextContainer>
          <TextContainer>
            <Typography
              fontSize='20px'
              lineHeight='24px'
            >
              Amount:
            </Typography>
            <Typography
              fontSize='20px'
              lineHeight='24px'
              fontWeight='500'
              color='black'
            >
              {data.is_color ? price.price_color : price.price_black_and_white} KD
            </Typography>
          </TextContainer>

        </FieldSet>
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          alignItems: "center",
          fontSize: "48px",
          // height: '340px',
          // border: '2px solid rgb(0 0 0 / 24%)',
          width: '80%',
        }}
      >
        <Left>

          <Grid
            item
            xs={12}
            md={9}
            sx={{


              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "48px",
              height: "100%",
              // borderTop: '2px solid rgb(0 0 0 / 24%)',
              marginTop: '15px',
              width: '-webkit-fill-available',
              gap: "56px",


              // height: "100%",
              // display: "flex",
              // justifyContent: "space-between",
              // flexDirection: "column",
              // alignItems: "center",
              // marginInline: 'auto',
              // fontSize: "48px",
              // // borderTop: '2px solid rgb(0 0 0 / 24%)',
              // gap: '50px',
              // width: '-webkit-fill-available',

            }}

          >
            <Typography

              marginTop='18px'
              fontSize={{ md: '28px', lg: '28px', sm: '58px' }}
              lineHeight='40px'
              fontWeight='500'
              textAlign="center"
              color="#000"
            >
              Pay Using Prepaid Code
            </Typography>
            <Item style={{ gap: "15px" }}>
              <Typography
                fontSize={{ md: '22px', lg: '22px', sm: '28px' }}
                lineHeight='40px'
              // fontWeight='400'
              >
                Use coupon code at payment
              </Typography>
              <CardContainer>
                <Avatar
                  variant="square"
                  alt='master card'
                  src="/assets/images/coupon.png"
                  sx={{ width: 70, height: 'auto' }}
                />
              </CardContainer>
            </Item>


            {
              (!CouponApplied)
                ?
                (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>

                  <TextField
                    // row
                    fullWidth
                    value={state.code}
                    onChange={(event, newValue) => {
                      console.log(event.target.value);
                      setState({ ...state, code: event.target.value })
                    }}
                    size="large"
                    name="coupon_code"
                    label="Coupon Code"
                    // variant="filled"
                    sx={{
                      width: { md: "100%", xs: "100%" },
                      marginBottom: { sm: '28px' },
                      // marginTop: '46px'
                    }}
                  />
                  <LoadingButton
                    type="submit"
                    disabled={!state.code && state.code == ''}
                    color="primary"
                    loading={loading}
                    variant="filled"
                    onClick={handleCouponApply}
                    sx={{
                      marginTop: { md: "10px", xs: "0" },
                      paddingInline: '30%',
                      fontSize: "25px",
                      width: "20%",
                      color: "white",
                      background: 'linear-gradient(270deg, #0076E2, #0AE3FF)',
                      "&:hover": {
                        backgroundColor: "white", // Change background color on hover
                        color: "white", // Change text color on hover
                      },
                    }}
                  >
                    Apply
                  </LoadingButton>
                </div>)
                :
                (<SavedItem>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px'
                    }}
                  >
                    <CheckCircleIcon />
                    <Typography
                      fontSize='20px'
                      fontWeight='600'
                      lineHeight='40px'
                      color='green'
                    >
                      {CouponApplied} KD deducted
                    </Typography>
                  </Box>
                  <Typography
                    fontSize='18px'
                    lineHeight='40px'
                    color='green'
                  >
                    1 coupon applied on this print
                  </Typography>
                </SavedItem>)
            }
          </Grid>

        </Left>
        <Test>or</Test>
        <Right>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "48px",
              height: "100%",
              // borderTop: '2px solid rgb(0 0 0 / 24%)',
              marginTop: { md: '15px', lg: '15px', sm: '58px' },
              width: '-webkit-fill-available',
              gap: "56px",
            }}
          >
            <Item
              style={{ height: "100%", justifyContent: "space-between" }}
            >
              <Typography
                fontSize={{ md: '28px', lg: '28px', sm: '58px' }}
                lineHeight='40px'
                fontWeight='500'
                textAlign='center'
              >
                Select Payment Option
              </Typography>
              <Item style={{ gap: "15px" }}>
                <Typography
                  fontSize={{ md: '22px', lg: '22px', sm: '28px' }}
                  lineHeight='40px'
                // fontWeight='400'
                >
                  Online Payment (K Net, Mastercard, Visa)
                </Typography>
                <CardContainer>
                  <Avatar
                    variant="square"
                    alt='master card'
                    src="/assets/images/payment-methods/mastercard.png"
                    sx={{
                      height: { sm: 'auto' },
                      width: { md: 80, lg: 80, sm: 100 },
                      marginTop: { sm: '50px' }
                    }}
                  />
                  <Avatar
                    variant="square"
                    alt='visa card'
                    src="/assets/images/payment-methods/visacard.png"
                    sx={{
                      height: { sm: 'auto' },
                      width: { md: 80, lg: 80, sm: 100 },
                      marginTop: { sm: '50px' }
                    }}
                  />
                  <Avatar
                    variant="square"
                    alt='knet'
                    src="/assets/images/payment-methods/knet.png"
                    sx={{
                      height: { sm: 'auto' },
                      width: { md: 80, lg: 80, sm: 100 },
                      marginTop: { sm: '50px' }
                    }}
                  />
                </CardContainer>
              </Item>
              <Item style={{ gap: "18px", marginTop: {md: '28px', lg: '28px'}}}>
                <TotalAmountContainer>

                  <Typography

                    fontSize='22px'
                    lineHeight='24px'
                    fontWeight='500'
                  >
                    Total Payable Amount
                  </Typography>
                  <Typography
                    fontSize='36px'
                    lineHeight='37px'
                    fontWeight='600'
                  >
                    {payableAmount} KD
                  </Typography>
                </TotalAmountContainer>
                <LoadingButton
                  type="submit"
                  color="primary"
                  loading={loading}
                  variant="filled"
                  onClick={handlePay}
                  sx={{
                    marginTop: {sm: '25px'},
                    fontSize: "25px",
                    width: "20%",
                    background: 'linear-gradient(270deg, #0076E2, #0AE3FF)',
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white", // Change background color on hover
                      color: "white", // Change text color on hover
                    },
                  }}
                >
                  Pay
                </LoadingButton>
              </Item>
            </Item>
          </Grid>

        </Right>
      </Grid>
    </RootContainer >
  )
}

export default Payment
