import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import CouponCodesCreate from './CouponCodesCreate';
import CouponCodesView from './CouponCodesView';

const CouponCodes = Loadable(lazy(() => import('./CouponCodes')));

const couponCodeRoutes = [
  { path: '/coupon-code', element: <CouponCodes />, auth: authRoles.admin },
  { path: '/coupon-code/create', element: <CouponCodesCreate />, auth: authRoles.admin },
  { path: '/coupon-code/view/:couponCodeId', element: <CouponCodesView />, auth: authRoles.admin },

];

export default couponCodeRoutes;
