export const navigations = [
  { name: "Dashboard", path: "/dashboard/default", icon: "dashboard" },
  { name: "Printers", path: "/printers", icon: "print" },
  { name: "Computers", path: "/computers", icon: "computer" },
  {
    name: "Ready Form",
    icon: "library_books",
    children: [
      { name: "Categories", iconText: "C", path: "/form-categories" },
      { name: "Sub Categories", iconText: "SC", path: "/form-sub-categories" },
      { name: "Forms", iconText: "F", path: "/ready-forms" },
    ],
  },
  {
    name: "I Print",
    icon: "rate_review",
    children: [
      { name: "Categories", iconText: "C", path: "/print-categories" },
      { name: "Sub Categories", iconText: "SC", path: "/print-sub-categories" },
      { name: "Forms", iconText: "F", path: "/print-forms" },
    ],
  },
  {
    name: "Payment And Service",
    icon: "local_atm"   ,
    children: [
      { name: "Categories", iconText: "C", path: "/link-categories" },
      { name: "Sub Categories", iconText: "SC", path: "/link-sub-categories" },
      { name: "Links", iconText: "L", path: "/links" },
    ],
  },
  { name: "Coupon Code", path: "/coupon-code", icon: "request-code" },
  { name: "Print History", path: "/reports", icon: "history" },
  { name: "Settings", path: "/settings", icon: "settings" },
];
